import React from "react";
import CookiesContainer from "./CookiesContainer";

const Footer = () => {
  return (
    <>
      <CookiesContainer />
      <div className="footer__main">
        <p>
          Merago Inc. Mountain View, California, USA. ©2023 All rights reserved
          - v00.90.06.53
        </p>
      </div>
    </>
  );
};

export default Footer;
