import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetAgentByUserId } from "../../../hooks/useAgent";
import { useLogout } from "../../../hooks/useLogout";
import AgentLoginMenu from "./AgentLoginMenu";
import withOutsideClickAgent from "./withOutsideClickAgent";
import AgentMainMenu from "../AgentMainMenu";
import assets from "../../../utils/Assets";

const AgentMenu = React.forwardRef(({ setOpen, open }, ref) => {
  const { refetch, data: logoutData } = useLogout();
  const location = useLocation();
  const useAuth = () => {
    const user = localStorage.getItem("agentID");
    return Boolean(user);
  };
  const user = useAuth();

  const navigation = useNavigate();
  const { data: agentData } = useGetAgentByUserId(
    localStorage.getItem("agentID") ? localStorage.getItem("agentID") : ""
  );

  const logout = () => {
    refetch();
    if (logoutData?.status === "SUCCESS") {
      localStorage.removeItem("agentAu");
    }
    localStorage.removeItem("agentID");
    localStorage.removeItem("agentAu");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("isSCAdmin");
    localStorage.removeItem("refresh_token");
    navigation("/agent");
    setOpen(!open);
  };

  useEffect(() => {
    if (agentData?.status === "SUCCESS") {
      localStorage.setItem("agentFname", agentData?.agentDetails?.firstname);
      localStorage.setItem("agentLname", agentData?.agentDetails?.lastname);
    }
  }, [agentData]);

  return (
    <>
      {user && (
        <div className="navbar" ref={ref}>
          <div className="navbar__left">
            <div className="navbar__logo">
              <img
                src="https://static.meragoapp.com/images/menuLogo.png"
                alt="logo"
                width="55px"
                height="55px"
              />
            </div>
            <div className="navbar__items">
              <AgentMainMenu
                ref={ref}
                agentData={agentData}
                setOpen={setOpen}
                open={open}
                logout={logout}
              />
            </div>
          </div>
          <div className="sideBar__main" onClick={() => setOpen(!open)}>
            <p>
              {agentData?.agentDetails?.firstname}{" "}
              {agentData?.agentDetails?.lastname}
            </p>
            <img src={assets.downArrow} alt="merago" />
          </div>
          {open ? (
            <div className="sidebar__conatiner agent__sidebar">
              {location.pathname !== "/agent" && (
                <button onClick={logout}>Logout</button>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <AgentLoginMenu />
    </>
  );
});

export default withOutsideClickAgent(AgentMenu);
