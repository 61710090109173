import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "../../../hooks/useLogout";
import assets from "../../../utils/Assets";
import withOutsideClickNurse from "./withOutsideClickNurse";
import {
  useGetDashboardMenuStatus,
  useGetNurseByUserId,
} from "../../../hooks/useNurse";
import useSound from "use-sound";

const NurseMenu = React.forwardRef(({ setOpen, open }, ref) => {
  const { refetch, data: logoutData } = useLogout();
  const location = useLocation();
  const navigate = useNavigate();
  const nurseID = localStorage.getItem("nurseID") || "";

  const { data: menuData } = useGetDashboardMenuStatus();
  const { data: nurseData } = useGetNurseByUserId(nurseID);

  const isUserAuthenticated = Boolean(nurseID);

  // Sound hooks
  const [playTalkToNurse] = useSound(process.env.REACT_APP_ALERT_NOTIFICATION, {
    volume: 0.9,
  });
  const [playEmergencyCare] = useSound(
    process.env.REACT_APP_ALERTU_NOTIFICATION,
    {
      volume: 0.9,
    }
  );
  const [playTalkToDoc] = useSound(process.env.REACT_APP_TALKDOC_NOTIFICATION, {
    volume: 0.9,
  });
  const [playJingle] = useSound(process.env.REACT_APP_JINGLE_NOTIFICATION, {
    volume: 0.9,
  });

  const restrictedPaths = ["/nurse-service-details", "/nurse-consultation"];
  const shouldPlaySound = !restrictedPaths.some((path) =>
    location.pathname.includes(path)
  );

  // Ref to store the previous state
  const previousStatusRef = useRef({});

  useEffect(() => {
    const currentStatus = menuData?.agentDashboardMenuStatus || {};

    const playSounds = () => {
      if (!shouldPlaySound) return;

      // Play specific sound only if the count increased
      if (
        currentStatus.talkToNurseReq > 0 &&
        currentStatus.talkToNurseReq !==
          previousStatusRef.current.talkToNurseReq
      ) {
        playTalkToNurse();
      }
      if (
        currentStatus.emergencyCareReq > 0 &&
        currentStatus.emergencyCareReq !==
          previousStatusRef.current.emergencyCareReq
      ) {
        playEmergencyCare();
      }
      if (
        currentStatus.talkToDocReq > 0 &&
        currentStatus.talkToDocReq !== previousStatusRef.current.talkToDocReq
      ) {
        playTalkToDoc();
      }

      // Update the previous status
      previousStatusRef.current = { ...currentStatus };
    };

    // Play sounds on state update
    playSounds();

    // Prevent the jingle logic if the path is restricted
    if (!shouldPlaySound) return;

    // Start the interval after the initial timeout
    const jingleInterval = setInterval(() => {
      if (
        currentStatus.talkToNurseReq > 0 ||
        currentStatus.emergencyCareReq > 0 ||
        currentStatus.talkToDocReq > 0
      ) {
        playJingle();
      }
    }, 37000);

    // Clear interval on unmount
    return () => clearInterval(jingleInterval);
  }, [
    menuData?.agentDashboardMenuStatus,
    location.pathname,
    shouldPlaySound,
    playTalkToNurse,
    playEmergencyCare,
    playTalkToDoc,
    playJingle,
  ]);

  useEffect(() => {
    if (nurseData) {
      localStorage.setItem("nurseUn", nurseData?.agentDetails?.firstname);
    }
  }, [nurseData]);

  const handleLogout = () => {
    refetch();
    if (logoutData?.status === "SUCCESS") {
      [
        "nurseID",
        "nurseAuth",
        "expires_in",
        "nurse_refresh_token",
      ].forEach((key) => localStorage.removeItem(key));
      navigate("/login");
      setOpen(false);
    }
    navigate("/login");
  };

  return isUserAuthenticated ? (
    <div className="navbar" ref={ref}>
      <div className="navbar__left">
        <div className="navbar__logo">
          <img
            src="https://static.meragoapp.com/images/menuLogo.png"
            alt="logo"
            width="55"
            height="55"
          />
        </div>
        <div className="navbar__items nav__more__left">
          {!location.pathname.includes("/nurse-consultation") && (
            <>
              <Link
                to="talk-nurse"
                className={
                  location.pathname.includes("/talk-nurse")
                    ? "sidebar_active"
                    : ""
                }
              >
                {menuData?.agentDashboardMenuStatus?.talkToNurseReq > 0 && (
                  <div className="red__alert"></div>
                )}
                Home
              </Link>
              <Link
                to="nurse-dashboard"
                className={
                  location.pathname.includes("/nurse-dashboard")
                    ? "sidebar_active"
                    : ""
                }
              >
                {(menuData?.agentDashboardMenuStatus?.emergencyCareReq > 0 ||
                  menuData?.agentDashboardMenuStatus?.talkToDocReq > 0) && (
                  <div className="red__alert"></div>
                )}
                Nurse Care
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="sideBar__main" onClick={() => setOpen(!open)}>
        <p>
          {nurseData?.agentDetails?.firstname}{" "}
          {nurseData?.agentDetails?.lastname}
        </p>
        <img src={assets.downArrow} alt="merago" />
      </div>
      {open && (
        <div className="sidebar__conatiner agent__sidebar">
          {location.pathname !== "/agent" && (
            <button onClick={handleLogout}>Logout</button>
          )}
        </div>
      )}
    </div>
  ) : null;
});

export default withOutsideClickNurse(NurseMenu);
