import { useMutation, useQuery, useQueryClient } from "react-query";
import * as agent from "../services/agentAPI";
const _ = require("lodash");

//* Get Agent Details
export const useGetAgentByUserId = (params) => {
  return useQuery(
    ["Agent-Details", params],
    () => agent.getAgentByUserId({ userId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

//? Home Services List
export const useHomeServicesList = () => {
  return useMutation((data) => agent.getAgentServicesWithFilter(data), {
    retry: false,
  });
};

//? AlertU List
export const useAgentAlertWithFilter = () => {
  return useMutation((data) => agent.getAgentAlertWithFilter(data), {
    retry: false,
  });
};

//* Get Service & Product Details
export const useGetServiceProductDetails = (params) => {
  return useQuery(
    ["Service-Details", params],
    () => agent.getServiceProductDetails(params),
    {
      enabled: !!params?.serviceId,
      retry: false,
      refetchInterval: 5000,
    }
  );
};

//* Get Profile DP
export const useGetProfileDPforAgent = (params) => {
  return useQuery(["Profile-DP", params], () => agent.getProfileDP(params), {
    enabled: !!params?.userId,
    retry: false,
  });
};
//* Get Member Details by UserId
export const useGetMemberDetailsForAgent = (params) => {
  return useQuery(
    ["Member-Details", params],
    () => agent.getMemberDetails({ userId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

//* Get Service History Details
export const useGetAgentServiceHistory = (params) => {
  return useQuery(
    ["Service-Details-History", params],
    () => agent.getServiceStatusHistory({ serviceId: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

//? Update Start Service Status
export const useStartServiceByAgent = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => agent.startServiceByAgent(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Service-Details");
    },
  });
};
//? Update Service Status
export const useUpdateServiceStatus = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => agent.updateAgentServiceStatus(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Service-Details");
      queryClient.invalidateQueries("Service-Details-History");
    },
  });
};

//? Reassign to self
export const useReassignToSelf = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => agent.updateReassignToSelf(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Service-Details");
      queryClient.invalidateQueries("Service-Details-History");
    },
  });
};

//? Update Product Status
export const useUpdateProductStatus = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => agent.updateProductStatus(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Service-Details");
      queryClient.invalidateQueries("Service-Details-History");
    },
  });
};

//* Get Service Chat History Details
export const useGetServiceChatHistory = (params) => {
  return useQuery(
    ["Service-Chat", params],
    () => agent.getServiceChatHistory({ serviceNumber: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      refetchInterval: 2000,
    }
  );
};

//* Get Service Chat History Details
export const useGetServiceChatHistoryNoPolling = (params) => {
  return useQuery(
    ["Service-Chat-No", params],
    () => agent.getServiceChatHistory({ serviceNumber: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
      refetchInterval: 10000,
    }
  );
};

//? Send Chat Reply
export const useSendChatReplyAgent = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => agent.sendChatReplyAgent(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Service-Chat");
    },
  });
};
//? Send Chat Reply
export const useGenrateRazorpayLink = () => {
  return useMutation((data) => agent.getRazorpayLink(data), {
    retry: false,
  });
};

//* Get Product History
export const useGetProductHistory = (params) => {
  return useQuery(
    ["Product-History", params],
    () => agent.getSingleProductHistory(params),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

//* Get New Message List
export const useGetNewMessageList = (params) => {
  return useQuery(
    ["New-Message-List", params],
    () => agent.getNewMessageList(params),
    {
      retry: false,
      refetchInterval: 150000,
    }
  );
};

//* Get Profile Values
export const useGetMemberProfileForAgent = (params) => {
  return useQuery(
    ["Profile-Values-Agent", params],
    () => agent.getProfileValuesAgent(params),
    {
      enabled: !!params?.memberId,
      retry: false,
    }
  );
};

//? Write Prescription - Med Agent
export const useAgentPrescription = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => agent.agentPrescription(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Service-Details");
    },
  });
};

//* Get Generic Medicine List
export const useGenericAgentMedicine = (params) => {
  return useQuery(
    ["Generic-Medicine-Agent", params],
    () => agent.getGenericMedicine({ genericMedicineName: params }),
    {
      enabled: !_.isEmpty(params),
      retry: false,
    }
  );
};

//? Casenotes for product
export const useProductCaseNotes = () => {
  return useMutation((data) => agent.getProductCaseNotes(data), {
    retry: false,
  });
};

export const useGetDocsById = () => {
  return useMutation((data) => agent.getDocsByIdAgent(data), {
    retry: false,
  });
};

//? Upload Lab Report
export const useUploadLabReport = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => agent.uploadLabReport(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Service-Details");
      queryClient.invalidateQueries("Service-Details-History");
    },
  });
};
//? Reassign to self
export const useDeleteSORecord = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => agent.updateMemberScanReportStatus(data), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries("Service-Details");
      queryClient.invalidateQueries("Service-Details-History");
    },
  });
};
//* Get Menu Status
export const useGetDashboardStatusAgent = () => {
  return useQuery(
    ["Dashbaord-MenuStatus"],
    () => agent.getDashboardStatusAgent(),
    {
      retry: false,
      refetchInterval: 8000,
      refetchIntervalInBackground: true,
    }
  );
};
